import { Box, IconButton, Typography } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();
  return (
    <Box p={2}>
      <Box display='flex' alignItems='center' mb={3}>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{ color: "#000" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography color='#000'>Support</Typography>
      </Box>
      <Typography>Email: support@butterfliex.com</Typography>
      <Box
        sx={{ mt: 2, color: "green", border: "1px solid green", p: 2 }}
        display='flex'
        alignItems='center'
        component='a'
        target='_blank'
        href='https://api.whatsapp.com/message/WED4NN7J5F4WH1?autoload=1&app_absent=0'
      >
        <WhatsAppIcon sx={{ mr: 1 }} />
        <Typography>Chat up Bolu, Always Online!</Typography>
      </Box>
    </Box>
  );
};

export default Support;
