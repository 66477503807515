import { lazy } from "react";
import { IRoutes } from ".";
const Account = lazy(() => import("../../pages/User/Account/index"));
const EditAccount = lazy(() => import("../../pages/User/Account/EditAccount"));
const ChangePassword = lazy(
  () => import("../../pages/User/Account/ChangePassword")
);
const Tickets = lazy(() => import("../../pages/User/Tickets/AllTickets"));
const RaiseTicket = lazy(() => import("../../pages/User/Tickets/RaiseTicket"));
const TicketChat = lazy(
  () => import("../../pages/User/Tickets/Chat/TicketChat")
);
const TransactionDetails = lazy(
  () => import("../../pages/Dashboard/Transaction/TransactionDetails")
);
const Contact = lazy(() => import("../../pages/Contact"));

export const userRoutes: IRoutes[] = [
  {
    path: "account",
    element: <Account />,
    authGuard: true,
  },
  {
    path: "account/edit",
    element: <EditAccount />,
    authGuard: true,
  },
  {
    path: "account/change-password",
    element: <ChangePassword />,
    authGuard: true,
  },
  {
    path: "account/tickets",
    element: <Tickets />,
    authGuard: true,
  },
  {
    path: "account/raise-ticket",
    element: <RaiseTicket />,
    authGuard: true,
  },
  {
    path: "account/chat/:ticketID",
    element: <TicketChat />,
    authGuard: true,
  },
  {
    path: "account/transaction-details",
    element: <TransactionDetails />,
    authGuard: true,
  },
  {
    path: "account/contact",
    element: <Contact />,
    authGuard: true,
  },
];
