import { Box } from "@mui/material";
import { Puff } from "react-loader-spinner";

const AppLoader = ({
  fullScreen,
  width,
  height,
}: {
  fullScreen?: boolean;
  width?: string;
  height?: string;
}) => {
  return (
    <Box
      style={{
        height: fullScreen ? "100vh" : "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Puff
        visible={true}
        height={`${height ? height : "100"}`}
        width={`${width ? width : "180"}`}
        color="#f58a44"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
};

export default AppLoader;
