import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { PaletteColor, Theme, createTheme } from "@mui/material";
const { palette } = createTheme();

declare module "@mui/material/styles" {
  interface Palette {
    neutral: PaletteColor;
  }
  interface PaletteOptions {
    neutral: PaletteColor;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xsm: true; // adds the `xsm` breakpoint
    xmd: true; // adds the `xsm` breakpoint
  }
}

export const theme: Theme = createTheme({
  typography: {
    fontFamily: "Manrope",
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 488,
      sm: 600,
      xmd: 758,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    //! Will refactor later for better readability
    primary: {
      main: "#f47422",
      light: "#fef2e9",
      50: "#fdf1ea",
      100: "#f9d3bf",
      200: "#f6be9f",
      300: "#f2a174",
      400: "#ef8e59",
      500: "#eb722f",
      600: "#d6682b",
      700: "#a75121",
      800: "#813f1a",
      900: "#633014",
    },
    neutral: palette.augmentColor({
      color: {
        50: "#e7e7e7",
        100: "#b6b6b6",
        200: "#929292",
        300: "#606060",
        400: "#414141",
        500: "#121212",
        600: "#101010",
        700: "#0d0d0d",
        800: "#0a0a0a",
        900: "#080808",
      },
    }),
    error: palette.augmentColor({
      color: {
        50: "#f7e6e6",
        100: "#e7b0b0",
        200: "#dc8a8a",
        300: "#cb5454",
        400: "#c13333",
        500: "#b20000",
        600: "#a20000",
        700: "#7e0000",
        800: "#620000",
        900: "#4b0000",
      },
    }),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          borderRadius: "12px",
          textTransform: "inherit",
        },
        contained: {
          color: "#fff",
          textTransform: "inherit",
        },
        containedInfo: {
          backgroundColor: "#FFF9F0",
          color: "#FF8100",
          fontSize: "14px",
          fontWeight: 500,
          borderRadius: "8px",
          padding: "6px 12px",
          "&:hover": {
            backgroundColor: "#FFF9F0",
            color: "#FF8100",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontWeight: 500,
          fontSize: "14px",
          textTransform: "inherit",
        },
        // Variant = filled
        filled: {
          background: "#F3FCF5",
          color: "#009C1F",
        },
        //Color => error
        colorError: {
          color: "#E70017",
          background: "#fef2f3",
        },
        //Color => warning
        colorWarning: {
          color: "#FF8100",
          background: "#FFF9F0",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          fontFamily: "Oxanium",
          color: "#0d0d0d",
          "& .MuiOutlinedInput-input": {
            padding: "12px 16px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#e7e7e7", // Default border color
            },
            "&:hover fieldset": {
              borderColor: "#929292", // Hover border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#f47422", // Focused border color
            },
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none", // Hide spin buttons in number input
            },
          "& input[type=number]": {
            MozAppearance: "textfield", // Hide spin buttons in Firefox
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          outline: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 600,
          color: "#0d0d0d",
          fontFamily: "Oxanium",
          "&::placeholder": {
            color: "#606060",
            fontWeight: 400,
            opacity: 1,
            fontFamily: "Manrope",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownRoundedIcon,
      },
      styleOverrides: {
        root: {
          color: "#060606",
          fontWeight: 600,
          fontFamily: "Oxanium",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e7e7e7",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f47422",
          },
          "&:hover.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f47422",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#929292", // Border color on hover
          },

          // "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "#e7e7e7", // Border color when disabled
          // },

          "& .Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          "& .MuiSelect-select": {
            //! Reuse later
            padding: "0 24px",
            display: "flex",
            alignItems: "center",
            fontWeight: 400, // Selected value font weight
            fontFamily: "Manrope",
          },
          "& .MuiSelect-icon": {
            right: "15px",
            color: "#0d0d0d",
          },
          "& .MuiSelect-selectPlaceholder": {
            color: "#606060",
            fontWeight: 400,
          },
          ".MuiOutlinedInput-input": {
            padding: "12px 16px",
            paddingRight: "24px",
          },
        },
        select: {
          "&.MuiInputBase-input": {
            // fontFamily: "Oxanium",
            "&.MuiSelect-selectPlaceholder": {
              color: "#929292", // Placeholder color
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // color: "#121212", // Default item color
          // fontWeight: 400, // Default font weight
          // fontFamily: "Oxanium",
          "&.Mui-selected": {
            color: "#0d0d0d", // Selected item color
            fontWeight: 600, // Selected item font weight
          },
        },
      },
    },
  },
});

export default theme;
