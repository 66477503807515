import { lazy } from "react";
import { IRoutes } from ".";
const Login = lazy(() => import("../../pages/auth/Login"));
const Register = lazy(() => import("../../pages/auth/Register"));
const ForgotPassword = lazy(() => import("../../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../../pages/auth/ResetPassword"));
const VerifyOTP = lazy(() => import("../../pages/auth/VerifyOTP"));

// Routes relating to user authentication
export const authRoutes: IRoutes[] = [
  {
    path: "/login",
    element: <Login />,
    authGuard: false,
  },
  {
    path: "/register",
    element: <Register />,
    authGuard: false,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    authGuard: false,
  },
  {
    path: "/forgot-password/reset-password",
    element: <ResetPassword />,
    authGuard: false,
  },
  {
    path: "/forgot-password/verify-otp",
    element: <VerifyOTP />,
    authGuard: false,
  },
];
