import { lazy } from "react";
import { IRoutes } from ".";
import { userRoutes } from "./user";
import DashboardLayout from "../../layout/DashboardLayout";
import { Navigate } from "react-router-dom";
import Support from "pages/Support";
import GetAllTrades from "pages/Trades/GetAllTrades";
const Dashboard = lazy(() => import("../../pages/Dashboard"));
const AllCoins = lazy(() => import("../../pages/Coins"));
const Trade = lazy(() => import("../../pages/Trades"));
const Transaction = lazy(
  () => import("../../pages/Dashboard/Transaction/TransactionDetails")
);
const PayBills = lazy(() => import("../../pages/PayBills"));
const MobileTopUp = lazy(() => import("../../pages/PayBills/MobileTopUp"));
const BuyData = lazy(() => import("../../pages/PayBills/BuyData"));
const Contact = lazy(() => import("../../pages/Contact"));

export const mainRoutes: IRoutes[] = [
  {
    path: "/",
    element: <DashboardLayout />,
    authGuard: true,
    children: [
      {
        path: "",
        element: <Navigate to='dashboard' />,
        authGuard: true,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        authGuard: true,
      },
      {
        path: "coins",
        element: <AllCoins />,
        authGuard: true,
      },
      {
        path: "trade",
        element: <Trade />,
        authGuard: true,
      },
      {
        path: "dashboard/transaction/:transactionID",
        element: <Transaction />,
        authGuard: true,
      },
      {
        path: "bills",
        element: <PayBills />,
        authGuard: true,
      },
      {
        path: "bills/mobile-top-up",
        element: <MobileTopUp />,
        authGuard: true,
      },
      {
        path: "bills/buy-data",
        element: <BuyData />,
        authGuard: true,
      },
      {
        path: "contact",
        element: <Contact />,
        authGuard: true,
      },
      {
        path: "support",
        element: <Support />,
        authGuard: true,
      },
      {
        path: "all-trades",
        element: <GetAllTrades />,
        authGuard: true,
      },
      ...userRoutes,
    ],
  },
];
