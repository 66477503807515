import { Fragment, Suspense } from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import AppLoader from "../shared/AppLoader";
import { routes, IRoutes } from "./routes";
import AuthGuard from "../shared/AuthGuard";

/**
 * This function creates a route object for each route defined in the routes array.
 * It recursively maps over the routes array and creates a new route object for each route.
 * Each route object has the following properties:
 * - path: The path of the route.
 * - element: The component that should be rendered when the route is active.
 * - children: An array of child route objects.
 * - authGuard: A boolean indicating whether the route requires authentication.
 *
 * If the route has an authGuard property set to true, the component that should be rendered
 * is wrapped in an AuthGuard component. This ensures that the component is only rendered
 * if the user is authenticated.
 *
 * If the route has children, the function recursively maps over the children array and
 * calls itself to create route objects for each child route.
 */

const createRouteElements = (route: IRoutes): RouteObject => {
  // Destructure the properties from the route object
  const { path, element, children, authGuard } = route;

  // Determine the component that should be rendered based on the authGuard property
  const Component = authGuard ? AuthGuard : Fragment;
  return {
    path, // The path of the route
    element: (
      <Suspense fallback={<AppLoader fullScreen />}>
        {/* Render a loading spinner while the component is being loaded*/}
        <Component>{element}</Component>
        {/* Render the component wrapped in the AuthGuard component if the route requires authentication*/}
      </Suspense>
    ),
    children: children ? children.map(createRouteElements) : undefined, // Recursively map over the children array and create route objects for each child route
  };
};

const router = createBrowserRouter(routes.map(createRouteElements));

export default router;
