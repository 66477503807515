import { useQuery } from "@tanstack/react-query";
import { getAllHistory, getCoin } from "../../services/trade";

export const useAllTradeQueries = (cryptoCurrency?: string) => {
  const {
    data: recentTradeData,
    isLoading: isTradesDataLoading,
    isError: isTradesDataError,
  } = useQuery({
    queryKey: ["ALL_TRADE"],
    queryFn: getAllHistory,
  });
  const {
    data: coinData,
    isLoading: isCoinsLoading,
    isError: isCoinsError,
  } = useQuery({
    queryKey: ["GET_COINS"],
    queryFn: getCoin,
  });

  const coins = coinData?.data?.map(
    ({
      coin_id: id,
      name,
      symbol,
      large: image,
      USDPrice: current_price,
      price_change_percentage_24h = "10%",
    }: any) => {
      return {
        id,
        name,
        symbol,
        image,
        current_price,
        price_change_percentage_24h,
      };
    }
  );
  const currentCurrencyPrice = coinData?.data.find(
    (item: { symbol: string }) => item.symbol === cryptoCurrency
  )?.["NairaPrice"];
  console.log(coinData);
  console.log(currentCurrencyPrice);

  const currentCurrencyObject = coinData?.data.find(
    (item: { symbol: string }) => item.symbol === cryptoCurrency
  );
  return {
    recentTradeData,
    isTradesDataLoading,
    isTradesDataError,
    coins,
    currentCurrencyPrice,
    currentCurrencyObject,
    isCoinsLoading,
    isCoinsError,
  };
};
