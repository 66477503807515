import { http } from "../utils/http";

/**
 * This file contains services related to trade operations
 */

/**
 * Interface for buy crypto request data
 */
export interface IBuyCrypto {
  // Symbol of the crypto being bought
  crypto_symbol: string;
  // Amount of crypto being bought
  quantity: number;
  // Price per unit of crypto
  price: number;
  // Address where the crypto is being sent
  send_to_address: string;
}

/**
 * Interface for sell crypto request data
 */
export interface ISellCrypto {
  // Symbol of the crypto being sold
  crypto_symbol: string;
  // Amount of crypto being sold
  quantity: number;
  // Price per unit of crypto
  price: number;
  // Address from where the crypto is being sent
  send_from_address: string;
  // Name of the bank
  bank_name: string;
  // Account number of the bank
  bank_account_number: string;
  // Name of the account holder
  bank_account_name: string;
}

/**
 * Interface for bank data
 */
export interface IBanks {
  // ID of the bank
  id: number;
  // Name of the bank
  name: string;
  // Slug of the bank
  slug: string;
  // Code of the bank
  code: string;
  // Whether the bank is active or not
  active: true;
}

/**
 * Interface for verified bank data
 */
export interface IVerfiedBankData {
  // Name of the account holder
  account_name: string;
  // Account number of the bank
  account_number: string;
  // ID of the bank
  bank_id: number;
}

/**
 * Get recent trade history
 */
export const getRecentTrade = async () => {
  // Fetch recent trade history
  const res = http.get("/trade/history/recent");
  return (await res).data;
};

export const getAllHistory = async () => {
  const res = http.get("/trade/history");
  return (await res).data;
};

/**
 * Get list of available coins
 */
export const getCoin = async () => {
  // Fetch available coins
  const res = http.get("/trade/coins");
  return (await res).data;
};

/**
 * Get list of available banks
 */
export const getBanks = async () => {
  // Fetch available banks
  const res = http.get("/users/banks");
  return (await res).data;
};

/**
 * Verify bank account
 * @param {number} accountNumber - Account number of the bank
 * @param {string} bankCode - Code of the bank
 */
export const verifyBankAccount = async (
  accountNumber: number,
  bankCode: string
) => {
  // Verify the bank account
  const res = http.get(
    `/users/bank-account/verify/${accountNumber}/${bankCode}`
  );
  return (await res).data;
};

/**
 * Buy crypto
 * @param {IBuyCrypto} data - Data for buying crypto
 */
export const buyCrypto = async (data: IBuyCrypto) => {
  // Buy crypto
  const res = http.post("/trade/buy", {
    trade_type: "buy",
    has_transferred: false,
    status: "pending",
    // Include the data for buying crypto
    ...data,
  });
  return (await res).data;
};

/**
 * Sell crypto
 * @param {ISellCrypto} data - Data for selling crypto
 */
export const sellCrypto = async (data: ISellCrypto) => {
  // Sell crypto
  const res = http.post("/trade/sell", {
    trade_type: "sell",
    // Include the data for selling crypto
    ...data,
  });
  return (await res).data;
};

export const getTransactionByID = async (transactionID: string) => {
  // Get Transaction by transactionID
  const res = http.get(`/trade/${transactionID}`);
  return (await res).data.data;
};
