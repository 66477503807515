import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useNavigate, useLocation } from "react-router-dom";

import WalletIcon from "@mui/icons-material/Wallet";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import HomeIcon from "@mui/icons-material/Home";
import SellIcon from "@mui/icons-material/Sell";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function SimpleBottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
      }}
    >
      <BottomNavigation
        showLabels
        value={location.pathname} // This will bind the current path to the BottomNavigation
        sx={{
          "&.MuiBottomNavigation-root": {
            height: "88px",
            boxShadow:
              "0px 2px 4px -2px #1018280F, 0px -4px 8px -2px #1018281A",
          },
          ".MuiBottomNavigationAction-label": {
            mt: 1,
            color: "#98A2B3",
            fontSize: "0.6rem",
          },
          ".Mui-selected": {
            fontSize: "0.65rem !important",
            fontWeight: 700,
            color: "primary.main", // Ensures text color changes to primary
          },
          ".Mui-selected .MuiBottomNavigationAction-iconOnly": {
            color: "primary.main", // Ensures icon color changes to primary
          },
        }}
      >
        <BottomNavigationAction
          value='/dashboard'
          onClick={() => navigate("/dashboard")}
          label='Home'
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          value='/trade'
          onClick={() => navigate("/trade")}
          label='Trade'
          icon={<SellIcon />}
        />
        <BottomNavigationAction
          value='/bills'
          onClick={() => navigate("/bills")}
          label='Bills'
          icon={<WalletIcon />}
        />
        <BottomNavigationAction
          value='/support'
          onClick={() => navigate("/support")}
          label='Support'
          icon={<LocalActivityIcon />}
        />
        <BottomNavigationAction
          value='/account'
          onClick={() => navigate("/account")}
          label='Account'
          icon={<AccountCircleIcon />}
        />
      </BottomNavigation>
    </Box>
  );
}
