export const ctaButton = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: "primary.50",
  border: 1,
  borderColor: "primary.100",
  p: 2,
  color: "primary.700",
};

export const ctaButtonText = {
  fontSize: "0.85rem",
  fontWeight: "500",
};
