import { useAllTradeQueries } from "hooks/trade/useAllTradeQueries";
import { useLocation, Link } from "react-router-dom";

import NorthEastIcon from "@mui/icons-material/NorthEast";

import { Box, Typography } from "@mui/material";
import { formatDate } from "utils/date";
import { ctaButton } from "styles/dashboard";
import AppLoader from "shared/AppLoader";

import emprtyTable from "assets/emptyTable.svg";

const GetAllTrades = () => {
  const location = useLocation();
  const {
    recentTradeData,
    isTradesDataLoading,
    // isTradesDataError,
    // coins,
    // isCoinsLoading,
    // isCoinsError,
  } = useAllTradeQueries();

  console.log("Recent trade data", recentTradeData);

  const statusColor = (status: string) => {
    let color;
    if (status === "inprogress") {
      color = "primary.main";
    } else if (status === "completed") {
      color = "#008000";
    } else {
      color = "#ff0000";
    }
    return color;
  };

  if (isTradesDataLoading) {
    return <AppLoader />;
  }
  return (
    <>
      <Box p={2}>
        {" "}
        <Box>
          <Typography textAlign='center' fontWeight={600} fontSize='22px'>
            All trades
          </Typography>
        </Box>
        {recentTradeData?.item?.length === 0 && (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            textAlign='center'
            height='60vh'
          >
            <img src={emprtyTable} alt='empty-table' />
            <Typography>No trade yet!</Typography>
          </Box>
        )}
        {recentTradeData?.item?.map(
          ({
            user_id: id,
            trade_type: tradeType,
            crypto_symbol: coin,
            created_at: date,
            total_amount: amount,
            symbol,
            status,
            transaction_id,
          }: any) => (
            <Link
              key={id}
              to={`/dashboard/transaction/${transaction_id.slice(3)}`}
              style={{ textDecoration: "none", color: "inherit" }}
              state={{ from: location.pathname }}
            >
              <Box
                paddingY={"18px"}
                display='flex'
                alignItems={"center"}
                justifyContent='space-between'
                borderBottom={1}
                borderColor={"#D8E3EE"}
              >
                <Box display='flex' alignItems={"center"} gap='10px'>
                  <Box sx={{ ...ctaButton, width: "48px", height: "48px" }}>
                    <NorthEastIcon />
                  </Box>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    flexDirection={"column"}
                    gap='10px'
                  >
                    <Box
                      fontSize={"18px"}
                      fontWeight='600'
                      lineHeight={"27px"}
                      fontFamily={"Oxanium"}
                      textTransform={"capitalize"}
                    >
                      {tradeType} - {coin}
                    </Box>
                    <Box
                      color={"#48627F"}
                      fontSize={"13px"}
                      fontWeight='400'
                      lineHeight={"19.5px"}
                    >
                      {formatDate(date)}
                    </Box>
                  </Box>
                </Box>

                <Box display='flex' alignItems={"center"}>
                  <Box
                    display='flex'
                    alignItems={"end"}
                    justifyContent='space-between'
                    flexDirection={"column"}
                    gap='10px'
                  >
                    <Typography
                      fontSize={"18px"}
                      fontWeight='600'
                      lineHeight={"27px"}
                      fontFamily={"Oxanium"}
                    >
                      {amount?.toLocaleString()} {symbol}
                    </Typography>
                    <Typography
                      fontSize={"13px"}
                      fontWeight='600'
                      lineHeight={"19.5px"}
                      textTransform='capitalize'
                      color={statusColor(status)}
                    >
                      {status === "inprogress" ? "In Progress" : status}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Link>
          )
        )}
      </Box>
    </>
  );
};

export default GetAllTrades;
