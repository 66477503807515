import { Box, Typography } from "@mui/material";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HomeIcon from "@mui/icons-material/Home";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SimpleBottomNavigation from "shared/BottomNavigation";

const DashboardLayout = () => {
  const location = useLocation();
  const NAV_LINKS = [
    {
      icon: <HomeIcon />,
      title: "Home",
      path: "/dashboard",
    },
    {
      title: "Trade",
      icon: <AccountBalanceWalletIcon />,
      path: "/trade",
    },
    {
      title: "Bills",
      icon: <CreditCardIcon />,
      path: "/bills",
    },
    {
      title: "Account",
      path: "/account",
      icon: <AccountBoxIcon />,
    },
  ];
  return (
    <Box
      display='flex'
      height='100vh'
      alignItems='center'
      justifyContent='center'
      maxWidth={1600}
      mx='auto'
    >
      <Box
        width='25%'
        sx={{
          display: { xs: "none", md: "flex" },
          p: { xs: 2, sm: 4 },
          flexDirection: "column",
        }}
        height='100%'
        borderRight='1px solid #e9eaed'
      >
        {NAV_LINKS.map(({ title, icon, path }) => (
          <NavLink
            key={title}
            style={{ textDecoration: "none", color: "inherit" }}
            to={path}
            state={{ from: location.pathname }}
          >
            {({ isActive }) => (
              <Box
                my={1}
                display='flex'
                bgcolor={isActive ? "#f4f4f4" : ""}
                alignItems='center'
                p={2}
                borderRadius='8px'
              >
                <Box mr={3}>{icon}</Box>
                <Typography>{title}</Typography>
              </Box>
            )}
          </NavLink>
        ))}
      </Box>
      <Box
        width='50%'
        sx={{
          width: { xs: "100%", md: "50%" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&": {
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            },
          }}
        >
          <Outlet />
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100vw",
              zIndex: 1,
              display: { md: "none", xs: "block" },
            }}
          >
            <SimpleBottomNavigation />
          </Box>
        </Box>
      </Box>
      <Box
        width='25%'
        sx={{ display: { xs: "none", md: "block" }, p: { xs: 2, sm: 4 } }}
        height='100%'
        borderLeft='1px solid #e9eaed'
      >
        <Typography>support@butterfliex.com</Typography>
        <Box
          sx={{ mt: 2, color: "green", border: "1px solid green", p: 2 }}
          display='flex'
          alignItems='center'
          component='a'
          target='_blank'
          href='https://api.whatsapp.com/message/WED4NN7J5F4WH1?autoload=1&app_absent=0'
        >
          <WhatsAppIcon sx={{ mr: 1 }} />
          <Typography>Chat up Bolu, Always Online!</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
