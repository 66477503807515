import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  if (localStorage.getItem("token")) {
    return <>{children}</>;
  }
  return <Navigate to='/login' />;
};

export default AuthGuard;
