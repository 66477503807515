export function formatDate(dateString: string) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Format the date as mm/dd/yyyy
  return `${month}/${day}/${year}`;
}

export const appFormatDate = (date: Date): string => {
  // Get individual components of the date
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1; // Months are zero-indexed, so we add 1
  const year: number = date.getFullYear();
  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();

  // Ensure components are padded with leading zeros if necessary
  const paddedDay: string = day < 10 ? `0${day}` : day.toString();
  const paddedMonth: string = month < 10 ? `0${month}` : month.toString();
  const paddedHours: string = hours < 10 ? `0${hours}` : hours.toString();
  const paddedMinutes: string =
    minutes < 10 ? `0${minutes}` : minutes.toString();

  // Construct the formatted date string
  const formattedDate: string = `${paddedDay}/${paddedMonth}/${year} ${paddedHours}:${paddedMinutes}`;

  if (date instanceof Date) {
    return formattedDate;
  } else {
    return "--";
  }
};

export function capitalize(str: string): string {
  if (typeof str !== "string" || str.length === 0) {
    return ""; // Return an empty string if the input is not a string or an empty string
  }
  return str.charAt(0).toUpperCase() + str.slice(1); // Capitalize the first letter and concatenate with the rest of the string
}

export function cutAndAddEllipses(text: string = "", maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + "...";
  }
}

export function convertToAmount(currency: string, amount: number | string) {
  return `${currency === "USD" ? "$" : "₦"}${Number(amount || 0)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
}

export function convertAmount(amount: number | string) {
  return Number(amount || 0)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getUserName = (user: {
  first_name: string;
  last_name: string;
}): string => {
  return `${capitalize(user?.first_name)} ${capitalize(user?.last_name)}`;
};
