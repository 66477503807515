import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import theme from "./theme";
import { toastOption } from "./config";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import * as serviceWorker from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Toaster toastOptions={toastOption} />
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

const config = {
  onUpdate: (registration: any) => {
    alert("App has been updated internally please refresh");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
  onSuccess: (registration: any) => {
    console.log("Content is cached for offline use.");
  },
};

serviceWorker.register(config);

reportWebVitals();
