import { authRoutes } from "./auth";
import { mainRoutes } from "./main";

export interface IRoutes {
  path: string;
  element: JSX.Element;
  authGuard: boolean;
  children?: IRoutes[];
}

// All routes
export const routes = [...authRoutes, ...mainRoutes];
